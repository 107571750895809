import { EffiAiLogo } from '@affine/core/components/icons';
import type { FC } from 'react';

import { modalHeaderWrapper } from './share.css';
export const ModalHeader: FC<{
  title: string;
  subTitle: string;
}> = ({ title, subTitle }) => {
  return (
    <div className={modalHeaderWrapper}>
      <EffiAiLogo />
      <p>
        {title} {subTitle}
      </p>
    </div>
  );
};
