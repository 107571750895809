import { DebugLogger } from '@affine/debug';
import { DocCollection } from '@blocksuite/store';

export { DocCollection };

export enum WorkspaceSubPath {
  ALL = 'all',
  TRASH = 'trash',
  SHARED = 'shared',
  TOOLS = 'tools',
  TEMPLATES = 'templates',
  AGENTS = 'agents',
  CHATBOTS = 'chatbots',
  MARKETPLACE = 'marketplace',
}

export const WorkspaceSubPathName = {
  [WorkspaceSubPath.ALL]: 'All Pages',
  [WorkspaceSubPath.TRASH]: 'Trash',
  [WorkspaceSubPath.SHARED]: 'Shared',
  [WorkspaceSubPath.TOOLS]: 'AI Tools',
  [WorkspaceSubPath.TEMPLATES]: 'Document Templates',
  [WorkspaceSubPath.AGENTS]: 'AI Agents',
  [WorkspaceSubPath.CHATBOTS]: 'Chatbots',
  [WorkspaceSubPath.MARKETPLACE]: 'Marketplace',
} satisfies {
  [Path in WorkspaceSubPath]: string;
};

export const pathGenerator = {
  all: workspaceId => `/workspace/${workspaceId}/all`,
  trash: workspaceId => `/workspace/${workspaceId}/trash`,
  shared: workspaceId => `/workspace/${workspaceId}/shared`,
  tools: workspaceId => `/workspace/${workspaceId}/tools`,
  templates: workspaceId => `/workspace/${workspaceId}/templates`,
  agents: workspaceId => `/workspace/${workspaceId}/agents`,
  chatbots: workspaceId => `/workspace/${workspaceId}/chatbots`,
  marketplace: workspaceId => `/workspace/${workspaceId}/marketplace`,
} satisfies {
  [Path in WorkspaceSubPath]: (workspaceId: string) => string;
};

export const performanceLogger = new DebugLogger('performance');
export const performanceRenderLogger = performanceLogger.namespace('render');
