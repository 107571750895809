import type {
  AffineAIPanelWidget,
  AffineSlashMenuActionItem,
  AffineSlashMenuContext,
  AffineSlashMenuItem,
  AffineSlashSubMenu,
  AIItemConfig,
} from '@blocksuite/blocks';
import {
  AFFINE_AI_PANEL_WIDGET,
  AffineSlashMenuWidget,
  AIStarIcon,
  MoreHorizontalIcon,
} from '@blocksuite/blocks';
import { assertExists } from '@blocksuite/global/utils';
import { html } from 'lit';

import { AIItemGroups } from '../../_common/config';
import { handleInlineAskAIAction } from '../../actions/doc-handler';
import { AIProvider } from '../../provider';
import { toggleEmbedAIGeneratorModal } from '../../_common/components/embed-ai-generator-modal';
import { callDifyApi } from '@affine/core/hooks/use-dify';

export function setupSlashMenuEntry(slashMenu: AffineSlashMenuWidget) {
  const AIItems = AIItemGroups()
    .map(group => group.items)
    .flat();

  const iconWrapper = (icon: AIItemConfig['icon']) => {
    return html`<div style="color: var(--affine-primary-color)">
      ${typeof icon === 'function' ? html`${icon()}` : icon}
    </div>`;
  };

  const showWhenWrapper =
    (item?: AIItemConfig) =>
    ({ rootComponent }: AffineSlashMenuContext) => {
      const affineAIPanelWidget = rootComponent.host.view.getWidget(
        AFFINE_AI_PANEL_WIDGET,
        rootComponent.model.id
      );
      if (affineAIPanelWidget === null) return false;

      const chain = rootComponent.host.command.chain();
      const editorMode = rootComponent.service.docModeService.getMode(
        rootComponent.doc.id
      );

      return item?.showWhen?.(chain, editorMode, rootComponent.host) ?? true;
    };

  const actionItemWrapper = (
    item: AIItemConfig
  ): AffineSlashMenuActionItem => ({
    ...basicItemConfig(item),
    action: ({ rootComponent }: AffineSlashMenuContext) => {
      item?.handler?.(rootComponent.host);
    },
  });

  const actionEffiAIItemWrapper = (
    item: AIItemConfig
  ): AffineSlashMenuActionItem => ({
    ...basicItemConfig(item),
    action: async ({ rootComponent, model }: AffineSlashMenuContext) => {
      //item?.handler?.(rootComponent.host);
      console.log(item);
      const parentModel = rootComponent.doc.getParent(model);
      if (!parentModel) {
        return;
      }
      const index = parentModel.children.indexOf(model) + 1;
      const { data } = await callDifyApi({
        method: 'get',
        url: `/apps/${item.data.id}`,
      });
      await toggleEmbedAIGeneratorModal(rootComponent.host, data, {
        mode: 'page',
        parentModel,
        index,
      });
      // tryRemoveEmptyLine(model);
    },
  });

  const subMenuWrapper = (item: AIItemConfig): AffineSlashSubMenu => {
    assertExists(item.subItem);
    return {
      ...basicItemConfig(item),
      subMenu: item.subItem.map<AffineSlashMenuActionItem>(
        ({ type, handler }) => ({
          name: type,
          action: ({ rootComponent }) => handler?.(rootComponent.host),
        })
      ),
    };
  };

  const basicItemConfig = (item: AIItemConfig) => {
    return {
      name: item.name,
      icon: iconWrapper(item.icon),
      alias: ['ai'],
      showWhen: showWhenWrapper(item),
    };
  };

  const menu = slashMenu.config.items.slice();
  menu.unshift({
    name: 'Ask AI',
    icon: AIStarIcon,
    showWhen: showWhenWrapper(),
    action: ({ rootComponent }) => {
      const view = rootComponent.host.view;
      const affineAIPanelWidget = view.getWidget(
        AFFINE_AI_PANEL_WIDGET,
        rootComponent.model.id
      ) as AffineAIPanelWidget;
      assertExists(affineAIPanelWidget);
      assertExists(AIProvider.actions.chat);
      assertExists(affineAIPanelWidget.host);
      handleInlineAskAIAction(affineAIPanelWidget.host);
    },
  });

  const AIMenuItems: AffineSlashMenuItem[] = [
    { groupName: 'EFFI AI' },
    ...AIItems.filter(({ name }) =>
      ['Fix spelling', 'Fix grammar'].includes(name)
    ).map(item => ({
      ...actionItemWrapper(item),
      name: `${item.name} from above`,
    })),

    ...AIItems.filter(({ name }) =>
      ['Summarize', 'Continue writing'].includes(name)
    ).map(actionItemWrapper),

    {
      name: 'Action with above',
      icon: iconWrapper(MoreHorizontalIcon),
      subMenu: [
        { groupName: 'Action with above' },
        ...AIItems.filter(({ name }) =>
          ['Translate to', 'Change tone to'].includes(name)
        ).map(subMenuWrapper),

        ...AIItems.filter(({ name }) =>
          [
            'Improve writing',
            'Make it longer',
            'Make it shorter',
            'Generate outline',
            'Find actions',
          ].includes(name)
        ).map(actionItemWrapper),
      ],
    },
  ];
  interface TreeNode {
    tag: object;
    children: { [key: string]: TreeNode };
    items?: AIItemConfig[];
  }
  const convertToTree = (items: AIItemConfig[]): TreeNode => {
    const root: TreeNode = {
      tag: { id: 'root', name: 'Effi Ai', tag: 'app' },
      children: {},
    };
    items.forEach(item => {
      let currentNode = root;
      item.data.tags.forEach(tag => {
        if (!currentNode.children[tag.id]) {
          currentNode.children[tag.id] = { tag, children: {} };
        }
        currentNode = currentNode.children[tag.id];
      });
      // At the final tag level, add the item to this node's items list
      if (!currentNode.items) {
        currentNode.items = [];
      }
      currentNode.items.push(item);
    });

    return root;
  };

  const buildEffiAiMenuItem = (
    tree: TreeNode,
    groups: AffineSlashMenuItem[] = []
  ) => {
    const subMenu = Object.entries(tree.children).map(i => {
      return [
        {
          name: i[1].tag.name,
          subMenu: buildEffiAiMenuItem(i[1], groups),
        },
      ];
    });
    groups = [...groups, ...subMenu];
    if (tree.items && tree.items.length) {
      groups = [...groups, ...tree.items.map(actionEffiAIItemWrapper)];
    }
    return groups;
  };

  const buildEffiAiMenuItems = (items: AIItemConfig[]) => {
    let groups: AffineSlashMenuItem[] = [];
    const tree = convertToTree(items);
    groups = [
      { groupName: 'EFFI AI Generate' },
      ...buildEffiAiMenuItem(tree, groups),
    ];
    return groups;
  };

  const EffiAIMenuItems: AffineSlashMenuItem[] = buildEffiAiMenuItems(
    AIItems.filter(
      ({ data }) => data && ['workflow', 'completion'].includes(data.mode)
    )
  );

  const basicGroupEnd = menu.findIndex(
    item => 'groupName' in item && item.groupName === 'List'
  );
  // insert ai item after basic group
  menu.splice(basicGroupEnd, 0, ...AIMenuItems, ...EffiAIMenuItems);

  slashMenu.config = {
    ...AffineSlashMenuWidget.DEFAULT_CONFIG,
    items: menu,
  };
}
