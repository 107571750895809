import "../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227iMBB95yusSpVAwiiwQKlRv2RVrZx4ErwNtmU7hO6q/76yk5TEuYhW+xLBxDNz5syZcVa/1uVZGMsj9HeGUMmZPRG0jqLH4wyhE/DsZAnabdTV/WfcqJy+E5Tm4A0055nA3MLZEJSAsKCd2b3GjGtILJeCIC1LZ86oIuhQhZIX0GkuS4KSnCtnUZI7fwwXENYQRAsrnR2XEL9xi6lSWEPmAzJNs+PsY7b6hP+TUUsxN7jkgsnSvDykNDfw8OrrUpQxLjKsq3o8hpb32h/qVTdSxkjRdQ6CoqbG6Xobciuy22iqWhQXAtjLg9VFU4YHZE6aizeCoo7Txh+IafKWaVkIRtCF6jnGNE25AMwTKbCBRApG9fvi2Op1he8TzV7dah9O9WM6VSw1A40TmUs9lajW2ESirU/UOPyoRXjmAtchG1Of3HF0ny9udLSw9jTwuzCWp+6IsCAsqfFaqu2EFnzAILOFqx3MWRjQ2EAOiSVISAG+gIpFTRkvDEHbqlAlDa+1CDm1/AJjEyIk7g3JthIWTZxjR1j30KU0P3eBx9J1jjJHfMerMuL1Yih9o+uJ+dxEwYBuvzMSO39gQC79SQ1F2ZOBH+W6CakUFpfNaoyiCR2c6bVJvj5ElXtSaOPEwSClRW47kPchZB/cK+emcchzrgw3w8ovT9wCNoom4FRQaqq+rEhfoeF/IHDw9qvptnVHTg4FCooYTRjqqB1qUKB3BJ6KSXJqLJYptu8KwmABtRWJLe+n0ZQDG/Xe67G3U5pXrcSHzn28H1nStz7dsbe74+/IbXF7+Ba3nYh+oF9DNYwH9gwORH2uVkLwOdAsxtsKpLGReWG9tV4c1bhIVf+KpbXyXP8JZzzgtj9J97YzWF71lhi+OECwbk8IN/N+X5aoonGBAlZq0NuRBTnc2+fejs+5AKpx5q4XEHa+eYoYZMs7dz/abVaH/eMS6Sym881ut0S3R7SKooUneXD7d7TyPyGGewztd6un9SfI7XaJ6scESDooveZLdGwaw0ujkepXRH+/EOjQx2orUny7FzPt9Lw+zj7+AXXNAdXoCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var labelIcon = '_1wmnsti8 _1wmnsti7';
export var pinSeparator = '_1wmnsti2';
export var root = '_1wmnsti0';
export var spacer = '_1wmnstib';
export var splitViewLabel = '_1wmnsti5';
export var splitViewLabelText = '_1wmnsti6';
export var splitViewSeparator = '_1wmnsti3';
export var tab = '_1wmnsti4';
export var tabCloseButton = '_1wmnstia _1wmnsti7';
export var tabCloseButtonWrapper = '_1wmnsti9';
export var tabIcon = '_1wmnsti7';
export var tabs = '_1wmnsti1';