// useAxios hook

import { useState, useEffect } from 'react';
import axios from 'axios';

export const useToken = () => {
  let token = JSON.parse(localStorage.getItem('user'))?.token || null;
  return token;
};

export const callDifyApi = ({
  url,
  method,
  params = [],
  body = null,
  headers = null,
}) => {
  const token = useToken();
  const api = axios.create({
    baseURL: process.env.DIFY_API_URL,
    headers: {
      authorization: `Bearer ${token?.access_token}`,
    },
  });

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        //place your reentry code
        let token = null;
        api
          .post(`/login`, {
            email: process.env.DIFY_USER_NAME,
            password: process.env.DIFY_PASSWORD,
            remember_me: true,
          })
          .then(({ data }) => {
            token = data.data;
            localStorage.setItem('user', JSON.stringify({ token: token }));
            window.location.reload();
          });
      }
      return error;
    }
  );

  return api[method](url, { params }, JSON.parse(headers), JSON.parse(body));
};

export const useDifyApi = ({
  url,
  method,
  params = [],
  body = null,
  headers = null,
}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);

  const fetchData = () => {
    callDifyApi({
      url,
      method,
      params,
      body,
      headers,
    })
      .then(res => {
        setResponse(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [method, url, body, headers]);

  return { response, error, loading };
};

export default useDifyApi;
