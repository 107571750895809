import { useAtomValue } from 'jotai';

import { navHeaderStyle } from '../index.css';
import { appSidebarOpenAtom } from '../index.jotai';
import Logo from '../../affine/onboarding/assets/logo';

export const SidebarHeader = () => {
  const open = useAtomValue(appSidebarOpenAtom);

  return (
    <div className={navHeaderStyle} data-open={open}>
      <Logo />
    </div>
  );
};

export * from './sidebar-switch';
