import "../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X3W6bMBS+z1NYmya1F0ZAkrZzpWlvMhl8SLwYG9kmpJv67hPGECBAiLSrxPY59vedf36e4CPTNAeDfkWvl9PvSm/R3w1C4Tf3g5DVVJpM6ZwgrSy18BQyODy/bxD63CC0XxCM3gaiUbggu33pyX5ughZN6DSo4AeJuYXcEJSCtKBryd+lsTz7wKmSFqTtHzFuCkE/CMoEXOqNVIkyl4agqF7Vu5hxDanlShJ/Wp8caEHQW3F5H6IIhDoohyVT0mLD/wBBZ6qfMKZZxiVgt3/EW8fBLSrgh6Ml6CUMPQKlR0qJoOnJaeRUH7jE2qsUDvQZtOUpFdgZgKCcMyZgBO0HKkjGtbFYZdh+FLAG5n4WpgeSKGtVTtCuQSJq3aOXjN+azRsjT/rpBqygj2HdzWKdQNV7LXKXF5QxLg8doW3YgC+U4Y3zNQhq+Xlo1yiouD2q0uIjl3bypji8eVDQBIQT7myTCJWe5vxv4WKxgVRJRnUdyELp53kvLBrK5CvtFI9gx/ed4O++2owmRonSQr07DhXILEFr3g1Aa6Xd61PGcaedTQaKFdWSy8Osqj+fUt45pYozeyQo2jeYW4zteio4aqKagcaaMl4aUte+eledQWdCVQQdOWPgqkhC09NBq1IyPJn2zUVT6AihmYXWKL6uffny3sMcjzDHY8x95/S84WuL+29V4f+13uslP0G0tGqax9csy+qTP5hLBheC4jnLDFklkCkNC7TicEirXd8zpShhITYb+t5TLVW/7Ci4ltD1JKw0d0aodb2OK26S57R54doro2BvEJcZl9yCi3c6LHn7+8mVUNNQGKTLfHPxtcG5cDuuQXtEZ7NCcHmaCrqXyfK2W1coX4flrm0FN73ZFDQFnICtoMmRe83ibWVhWFe6XKX1bfQ6JgwsHgX7h/OoC6nGVsP86ZH57si0L23jmQa6MNHMzECLwfAdBRoMuNRhcO1kUz1ijRGHQ8q439IBxygMz8d+2QrDc/WfSK9JpwGwoNK0KHxZfXhsoSjw2Jx+Ti/Yk3oNxyanKLDcihWDjRNbO4bdDDcUBaZMrk/dl3+8LiT3HPpt0p/zw/Wjnu5hSftte9tG+s8cGKfIpBpAIioZeup5JwrjXXF5dpqjKF1C2ZVCx7G4dK1ogoC7xViqbfuZMxd1Sy8O9JgX7+wqlYT2w2iZ8L5GOuKb+utGXvvcfP4Dwaij6/0NAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_17xkjwr6';
export var authCodeErrorMessage = '_17xkjwr8';
export var authCodeWrapper = '_17xkjwr7';
export var authContent = '_17xkjwr5';
export var authInputWrapper = '_17xkjwr1';
export var authPageContainer = '_17xkjwra';
export var formHint = '_17xkjwr2';
export var hideInSmallScreen = '_17xkjwrd';
export var input = '_17xkjwrc';
export var loading = '_17xkjwr4';
export var modalHeaderWrapper = '_17xkjwr0';
export var resendButtonWrapper = '_17xkjwr9';
export var signInPageContainer = '_17xkjwrb';