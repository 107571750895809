import { memo } from 'react';

export const EmptySvg = memo(function EmptySvg({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <svg
      className={className}
      style={style}
      width="146"
      height="98"
      viewBox="0 0 146 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_388_48586)">
        <path
          d="M10 17L12 19L16 15M6 8H20C21.1046 8 22 8.89543 22 10V24C22 25.1046 21.1046 26 20 26H6C4.89543 26 4 25.1046 4 24V10C4 8.89543 4.89543 8 6 8Z"
          stroke="#D9D9D9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g clip-path="url(#clip1_388_48586)">
          <rect x="30" y="7" width="113" height="8" rx="4" fill="#D9D9D9" />
          <rect x="30" y="19" width="72" height="8" rx="4" fill="#D9D9D9" />
        </g>
        <path
          d="M20 42H6C4.89543 42 4 42.8954 4 44V58C4 59.1046 4.89543 60 6 60H20C21.1046 60 22 59.1046 22 58V44C22 42.8954 21.1046 42 20 42Z"
          stroke="#D9D9D9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g clip-path="url(#clip2_388_48586)">
          <rect x="30" y="41" width="113" height="8" rx="4" fill="#D9D9D9" />
          <rect x="30" y="53" width="72" height="8" rx="4" fill="#D9D9D9" />
        </g>
        <path
          d="M10 85L12 87L16 83M6 76H20C21.1046 76 22 76.8954 22 78V92C22 93.1046 21.1046 94 20 94H6C4.89543 94 4 93.1046 4 92V78C4 76.8954 4.89543 76 6 76Z"
          stroke="#D9D9D9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g clip-path="url(#clip3_388_48586)">
          <rect x="30" y="75" width="113" height="8" rx="4" fill="#D9D9D9" />
          <rect x="30" y="87" width="72" height="8" rx="4" fill="#D9D9D9" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_388_48586">
          <rect width="146" height="98" fill="white" />
        </clipPath>
        <clipPath id="clip1_388_48586">
          <rect
            width="113"
            height="20"
            fill="white"
            transform="translate(30 7)"
          />
        </clipPath>
        <clipPath id="clip2_388_48586">
          <rect
            width="113"
            height="20"
            fill="white"
            transform="translate(30 41)"
          />
        </clipPath>
        <clipPath id="clip3_388_48586">
          <rect
            width="113"
            height="20"
            fill="white"
            transform="translate(30 75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});
