import type { EditorHost } from '@blocksuite/block-std';
import type { BlockModel, Doc } from '@blocksuite/store';
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { embedCardModalStyles } from './styles.js';
import { toast } from '@blocksuite/blocks';
import { AffineEditorContainer } from '@blocksuite/presets';
import { insertFromMarkdown } from '../markdown-utils.js';

export type IPostMessage = { type: string; payload: Record<string, any> };
export type EventHandler = (
  callback: (data: IPostMessage) => unknown,
  payload: IPostMessage['payload']
) => unknown;
export const postMessage = (
  data: IPostMessage,
  target: MessageEvent['source'],
  origin = '*'
) => target?.postMessage(data, { targetOrigin: origin });

@customElement('embed-ai-generator-modal')
export class EmbedAIGeneratorModal extends WithDisposable(ShadowlessElement) {
  private _onCancel = () => {
    this.remove();
  };

  private _onConfirm = async () => {
    if (!this.payload.message) {
      toast('Content empty');
    }
    const content = this.payload.message;

    const { mode } = this.createOptions;
    if (mode === 'page') {
      const { parentModel, index } = this.createOptions;
      await insertFromMarkdown(this.host, content, parentModel, index);
    } else if (mode === 'edgeless') {
      //   let flavour = 'affine:bookmark',
      //     targetStyle: EmbedCardStyle = 'vertical';
      //   if (embedOptions) {
      //     flavour = embedOptions.flavour;
      //     targetStyle = embedOptions.styles[0];
      //   }
      //   const edgelessRoot = getRootByEditorHost(
      //     this.host
      //   ) as EdgelessRootBlockComponent | null;
      //   assertExists(edgelessRoot);
      //   const surface = edgelessRoot.surface;
      //   const center = Vec.toVec(surface.renderer.viewport.center);
      //   edgelessRoot.service.addBlock(
      //     flavour,
      //     {
      //       url,
      //       xywh: Bound.fromCenter(
      //         center,
      //         EMBED_CARD_WIDTH[targetStyle],
      //         EMBED_CARD_HEIGHT[targetStyle]
      //       ).serialize(),
      //       style: targetStyle,
      //     },
      //     surface.model
      //   );
      //   edgelessRoot.tools.setEdgelessTool({
      //     type: 'default',
      //   });
    }
    this.onConfirm();
    this.remove();
  };

  private _onDocumentKeydown = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Enter' && !e.isComposing) {
      this._onConfirm();
    }
    if (e.key === 'Escape') {
      this.remove();
    }
  };

  static override styles = embedCardModalStyles;

  private _handleInput(e: InputEvent) {
    const target = e.target as HTMLInputElement;
    this._linkInputValue = target.value;
  }

  override connectedCallback() {
    super.connectedCallback();

    this.updateComplete
      .then(() => {
        requestAnimationFrame(() => {
          this.input.focus();
        });
      })
      .catch(console.error);
    this.disposables.addFromEvent(this, 'keydown', this._onDocumentKeydown);

    window.addEventListener(
      'message',
      ({ origin, source, data }: MessageEvent) => {
        const { type, payload } = data;
        if (type === 'ai_workflow_completion_response') {
          console.log(origin, source, data);
          this.payload = payload;
        }
      }
    );
  }
  private apiUrl = 'http://localhost:3000'; /*this.aiModel.site.app_base_url*/

  override render() {
    return html`<div class="embed-card-modal">
      <div class="embed-card-modal-mask" @click=${this._onCancel}></div>
      <div class="embed-card-modal-wrapper">
        <div class="embed-card-modal-row row">
          <input
            class="embed-card-modal-input link"
            id="card-description"
            type="hidden"
            placeholder="Input in https://..."
            value=${this._linkInputValue}
            @input=${this._handleInput}
          />
          <iframe
            src="${this.aiModel.site.app_base_url}/completion/simple/${this
              .aiModel.site.access_token}"
            style="width: 100%; height: 100%; min-height: 600px;"
            frameborder="0"
            allow="microphone"
          >
          </iframe>
        </div>

        <div class="embed-card-modal-row">
          <button
            class=${classMap({
              'embed-card-modal-button': true,
              save: true,
            })}
            @click=${this._onConfirm}
          >
            Effi AI Generate
          </button>
        </div>
      </div>
    </div>`;
  }

  @state()
  private accessor _linkInputValue = '';

  @property({ attribute: false })
  accessor createOptions!:
    | {
        mode: 'page';
        parentModel: BlockModel | string;
        index?: number;
      }
    | {
        mode: 'edgeless';
      };

  @property({ attribute: false })
  accessor aiModel!: object;

  @property({ attribute: false })
  accessor host!: EditorHost;

  @query('input')
  accessor input!: HTMLInputElement;

  @query('iframe')
  accessor iframe!: HTMLIFrameElement;

  @query('affine-editor-container')
  accessor editor!: AffineEditorContainer;

  @property({ attribute: false })
  accessor onConfirm!: () => void;

  @property({ attribute: false })
  accessor doc!: Doc;

  @property({ attribute: false })
  accessor payload!: object;
}

export async function toggleEmbedAIGeneratorModal(
  host: EditorHost,
  aiModel: object,
  createOptions:
    | {
        mode: 'page';
        parentModel: BlockModel | string;
        index?: number;
      }
    | {
        mode: 'edgeless';
      }
): Promise<void> {
  host.selection.clear();

  const embedAIGeneratorModal = new EmbedAIGeneratorModal();
  embedAIGeneratorModal.host = host;
  embedAIGeneratorModal.aiModel = aiModel;
  embedAIGeneratorModal.createOptions = createOptions;

  document.body.append(embedAIGeneratorModal);

  return new Promise(resolve => {
    embedAIGeneratorModal.onConfirm = () => resolve();
  });
}

declare global {
  interface HTMLElementTagNameMap {
    'embed-ai-generator-modal': EmbedAIGeneratorModal;
  }
}
