import { IconButton } from '@affine/component';
import { ToggleCollapseIcon, ToggleExpandIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import {
  type ForwardedRef,
  forwardRef,
  type PropsWithChildren,
  type SVGProps,
} from 'react';

import * as styles from './index.css';

export type CategoryDividerProps = PropsWithChildren<
  {
    label: string;
    icon?: SVGProps<SVGSVGElement>;
    className?: string;
    collapsed?: boolean;
    setCollapsed?: (collapsed: boolean) => void;
  } & {
    [key: `data-${string}`]: unknown;
  }
>;

export const CategoryDivider = forwardRef(
  (
    {
      label,
      icon,
      children,
      className,
      collapsed,
      setCollapsed,
      ...otherProps
    }: CategoryDividerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={clsx([styles.root, className])} ref={ref} {...otherProps}>
        <div style={{ paddingLeft: 0, paddingRight: 25 }}>{icon}</div>
        <div
          className={styles.label}
          onClick={() => setCollapsed?.(!collapsed)}
        >
          {label}
          {collapsed !== undefined && (
            <IconButton
              withoutHoverStyle
              className={styles.collapseButton}
              size="small"
              data-testid="category-divider-collapse-button"
            >
              {collapsed ? <ToggleCollapseIcon /> : <ToggleExpandIcon />}
            </IconButton>
          )}
        </div>
        <div style={{ flex: 1 }}></div>
        {children}
      </div>
    );
  }
);

CategoryDivider.displayName = 'CategoryDivider';
